import JSM from '../jsmodeler/jsmodeler.ext.three';
import THREE from '../jsmodeler/three.min.js';
import {
	Message
} from 'element-ui'
// 请求
import {
	service
} from '@/common/api'
import md5 from "js-md5";
//当前选中文件
let fileItem = {};
//文件队列
let selectFiles = [];
//文件格式
let fileFormat = "stp/step/stl/igs/obj";

var hasDev = window.config.api == '/papi';
var hasUp = false;

var viewer = null;

function setdView (viewName) {
	var eye, center, up;
	if (viewName == 'z') {
		eye = new JSM.Coord(1.0, 0.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(0.0, 0.0, 1.0);
	} else if (viewName == '-z') {
		eye = new JSM.Coord(-1.0, 0.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(0.0, 0.0, -1.0);
	} else if (viewName == 'y') {
		eye = new JSM.Coord(1.0, 0.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(0.0, 1.0, 0.0);
	} else if (viewName == '-y') {
		eye = new JSM.Coord(-1.0, 0.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(0.0, -1.0, 0.0);
	} else if (viewName == 'x') {
		eye = new JSM.Coord(0.0, 1.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(1.0, 0.0, 0.0);
	} else if (viewName == '-x') {
		eye = new JSM.Coord(0.0, -1.0, 0.0);
		center = new JSM.Coord(0.0, 0.0, 0.0);
		up = new JSM.Coord(-1.0, 0.0, 0.0);
	} else {
		return;
	}
	viewer.cameraMove.Set(eye, center, up);
	viewer.FitInWindow();
}

function ShowMesh (index, show) {
	viewer.scene.traverse(function (current) {
		if (current instanceof THREE.Mesh) {
			if (current.originalJsonMeshIndex == index) {
				if (show) {
					current.visible = true;
				} else {
					current.visible = false;
				}
			}
		}
	});
}
import {
	addButton
} from './fun';

//json格式转formdata
const getFormData = (obj) => {
	let formData = new FormData();
	Object.keys(obj).forEach(item => {
		formData.append(item, obj[item]);
	})
	return formData;
}

//新文件上传服务器
const upload = (callback, is) => {
	var params = {
		__ajax: "json",
		fileMd5: md5(fileItem.name),
		fileName: fileItem.name,
		uploadType: "file",
		fileUploadId: fileItem.fileUploadId,
		fileEntityId: fileItem.fileEntityId,
		file: fileItem.file
	};
	service
		.post("/a/file/upload.json", getFormData(params), {
			mum: true,
			transformRequest: [
				function (data) {
					return data;
				},
			],
			onUploadProgress: function (progressEvent) {
				let complete =
					((progressEvent.loaded / progressEvent.total) * 100) | 0;
				$('.box-l .loading span').html('文件上传中' + complete + '%');
				if (complete == 100) {
					$('.box-l .loading span').html('文件已上传' + complete + '%，服务器正在处理，请稍后...');
				}
			},
		})
		.then(function (res) {
			if (res.result == "true") {
				$('.box-l .loading span').html('上传成功！');
				selectFiles = selectFiles.map(item => {
					if (item.id == fileItem.id) {
						item.fileUploadId = res.fileUpload.id;
						item.fileEntityId = res.fileEntityId || res.fileUpload.fileEntity.id;
						item.fileUrl = res.fileUpload.fileUrl;
						item.response = res;
						fileItem = item;
					}
					return item;
				})
				upSuccess(callback, is);
			} else {
				$('.box-l .loading span').html(res.message);
			}
		})
		.catch(function (err) {
			$('.box-l .loading span').html('上传失败');
			Message({
				message: "上传失败！",
				type: "error"
			})
		});
}

//检查服务器是否已存在文件
const hasUpLoad = (callback, is) => {
	if (fileItem.id) {
		$('.box-l .loading').show().find('span').html('文件解析中0%');
		var params = {
			__ajax: "json",
			fileMd5: md5(fileItem.name),
			fileName: fileItem.name,
		};
		service
			.post("/a/file/upload.json", getFormData(params), {
				mum: true
			})
			.then((res) => {
				selectFiles = selectFiles.map(item => {
					if (item.id == fileItem.id) {
						item.fileUploadId = res.fileUpload.id;
						item.fileEntityId = res.fileEntityId || res.fileUpload.fileEntity.id;
						item.fileUrl = res.fileUpload.fileUrl;
						item.response = res;
						fileItem = item;
					}
					return item;
				})
				if (res.result == "true") {
					hasUp = true;
					$('.box-l .loading span').html('文件解析成功');
					upSuccess(callback, is);
				} else {
					hasUp = false;
					upload(callback, is);
				}
			})
			.catch((err) => {
				Message({
					message: "上传失败！",
					type: "error"
				})
			});
	} else {
		Message({
			message: '请选择文件！',
			type: 'error',
			duration: 1500
		})
	}
}

function upSuccess (callback, is) {
	if (fileItem.isTurn) {
		hasStatus(callback, is);
	} else {
		if (is) {
			callback ? callback() : null;
		} else {
			try {
				let _url = "";
				let _http = 'http:\/\/yizao.oss-cn-qingdao.aliyuncs.com';
				let _https = 'https:\/\/yizao.oss-cn-qingdao.aliyuncs.com';
				if (fileItem.fileUrl.split(_http).length > 1) {
					_url = fileItem.fileUrl.split(_http);
				} else if (fileItem.fileUrl.split(_https).length > 1){
					_url = fileItem.fileUrl.split(_https)
				}
				var params = {
					fileUploadId: fileItem.fileUploadId,
					text: `${window.config.dapi}#${_url?_url[1].split('?')[0]:fileItem.fileUrl}`
				};
				service.post('/api/v1/shareQRCode', getFormData(params)).then(function (res) {
					$('.box-l .loading').hide().find('span').html('加载中...');
					if (res.code == "1") {
						selectFiles = selectFiles.map(item => {
							if (item.id == fileItem.id) {
								item.shareCode = res.data.shareUrl;
								fileItem = item;
							}
							return item;
						})
						share();
					} else {
						Message({
							message: '获取分享二维码失败！',
							type: 'error',
							duration: 1500
						})
						share();
					}
				})
			} catch (error) {
				Message({
					message: '获取分享二维码失败！',
					type: 'error',
					duration: 1500
				})
			}
		}
	}
}

function hasStatus (callback, is) {
	$('.box-l .loading span').html('文件转码中，请稍后...');
	var params = {
		fileUploadId: fileItem.fileUploadId,
	};
	service.post('/api/v1/checkFileConverStatus', getFormData(params)).then(function (res) {
		if (res.code == 0) {
			setTimeout(function () {
				hasStatus(callback, is);
			}, 1000)
		} else if (res.code == 1) {
			$('.box-l .loading span').html('文件转码成功！');
			let shareUrl = res.data.shareUrl;
			if (!callback) {
				$('.box-l .loading span').html('加载中...');
				var params = {
					fileUploadId: fileItem.fileUploadId,
					text: shareUrl
				};
				service.post('/api/v1/shareQRCode', getFormData(params)).then(function (res2) {
					$('.box-l .loading').hide();
					if (res2.code == "1") {
						selectFiles = selectFiles.map(item => {
							if (item.id == fileItem.id) {
								item.fileUrl = shareUrl;
								item.shareCode = res2.data.shareUrl;
								fileItem = item;
							}
							return item;
						})
						share();
					} else {
						Message({
							message: '获取分享二维码失败！',
							type: 'error',
							duration: 1500
						})
						share();
					}
				})
			} else {
				selectFiles = selectFiles.map(item => {
					if (item.id == fileItem.id) {
						item.fileUrl = shareUrl;
						fileItem = item;
					}
					return item;
				})
				$('.box-l .loading').hide().find('span').html('加载中...');
				callback();
			}
		}
	}).catch(function (err) {
		setTimeout(function () {
			hasStatus(callback, is);
		}, 1000)
	})
}


function share () {
	window.parent.$('body').append(`<div class="share-dialog">
		<div class="share-back"></div>
		<div class="share-main">
		  <div class="t">
			<a
			  class="link"
			  target="_blank"
			  href="${fileItem.fileUrl}"
			>${fileItem.fileUrl}</a>
			<input
			  type="text"
			  class="copy-input"
			  value="${fileItem.fileUrl}"
			/>
			<div class="common-btn">点击复制链接</div>
			<div class="code">
			  <img src="${fileItem.shareCode}" alt="" />
			</div>
			<div class="title">扫码转发，支持手机端查看</div>
		  </div>
		  <div class="b">
			<img src="${require('@/assets/images/public/share-footer.jpg')}" alt="" />
		  </div>
		  <div class="btn-hide">×</div>
		</div>
	  </div>`)
	window.parent.$('.share-dialog .btn-hide').click(function () {
		$(this).closest('.share-dialog').remove();
	})
	window.parent.$('.share-dialog .common-btn').click(function copyText () {
		var input = $('.share-dialog .copy-input');
		input.select(); // 选中文本
		document.execCommand("copy"); // 执行浏览器复制命令
		Message({
			message: '复制成功！',
			type: 'success',
			duration: 1500
		})
	})

}

const Resize = () => {
	function SetWidth (elem, value) {
		elem.width = value;
		elem.style.width = value + 'px';
	}

	function SetHeight (elem, value) {
		elem.height = value;
		elem.style.height = value + 'px';
	}
	var canvas = document.getElementById('modelcanvas');
	SetHeight(canvas, $(".box-l").height() - 40);
	SetWidth(canvas, $(".box-l").width());
	$('.dialog').css('left', (($(window).width() - $('.dialog').width()) / 2.0) + 'px');
	$('.dialog').css('top', (($(window).height() - $('.dialog').height()) / 3.0) + 'px');
	window.jsmResize ? window.jsmResize() : null;
};
class FloatingDialog {
	constructor() {
		this.dialogDiv = null;
		this.contentDiv = null;
	};
	Open (parameters) {
		function AddButton (dialog, parent, button) {
			var buttonDiv = $('<div>').addClass('dialogbutton').html(button.text).appendTo(parent);
			buttonDiv.click(function () {
				button.callback(dialog);
			});
		}

		if (this.dialogDiv !== null) {
			this.Close();
		}

		this.dialogDiv = $('<div>').addClass('dialog').appendTo($('body'));
		$('<div>').addClass('dialogtitle').html(parameters.title).appendTo(this.dialogDiv);
		this.contentDiv = $('<div>').addClass('dialogcontent').appendTo(this.dialogDiv);
		if (parameters.text !== null && parameters.text !== undefined) {
			this.contentDiv.html(parameters.text);
		}
		var buttonsDiv = $('<div>').addClass('dialogbuttons').appendTo(this.dialogDiv);

		var i, button;
		for (i = 0; i < parameters.buttons.length; i++) {
			button = parameters.buttons[i];
			AddButton(this, buttonsDiv, button);
		}

		document.addEventListener('click', this.MouseClick.bind(this), true);
		this.Resize();
	};

	Close () {
		if (this.dialogDiv === null) {
			return;
		}

		this.dialogDiv.remove();
		this.dialogDiv = null;
		$('body').unbind('click');
	};

	GetContentDiv () {
		return this.contentDiv;
	};

	Resize () {
		if (this.dialogDiv === null) {
			return;
		}

		this.dialogDiv.css('left', ((document.body.clientWidth - this.dialogDiv.width()) / 2.0) + 'px');
		this.dialogDiv.css('top', ((document.body.clientHeight - this.dialogDiv.height()) / 3.0) + 'px');
	};

	MouseClick (clickEvent) {
		if (this.dialogDiv === null) {
			return;
		}

		var dialogClicked = false;
		var target = clickEvent.target;
		while (target !== null) {
			if (target === this.dialogDiv.get()[0]) {
				dialogClicked = true;
			}
			target = target.parentElement;
		}

		if (!dialogClicked) {
			this.Close();
		}
	};

}
class FloatingControl {
	constructor() {
		this.parent = null;
		this.controlDiv = null;
		this.contentDiv = null;
	};

	Open (parameters) {
		if (this.controlDiv !== null) {
			this.Close();
		}
		this.parent = parameters.parent;
		this.controlDiv = $('<div>').addClass('control').appendTo($('.make3d .box-l'));
		this.contentDiv = $('<div>').addClass('controlcontent').html(parameters.text).appendTo(this.controlDiv);
	};

	Close () {
		if (this.controlDiv === null) {
			return;
		}

		this.controlDiv.remove();
		this.controlDiv = null;
	};
}

function IsSet (val) {
	return val !== undefined && val !== null;
}
class InfoTable {
	constructor(parent) {
		this.table = $('<table>').addClass('infotable').appendTo(parent);
	};
	AddRow (name, value) {
		var tableRow = $('<tr>').appendTo(this.table);
		$('<td>').html(name).appendTo(tableRow);
		$('<td>').html(value).appendTo(tableRow);
	};
	AddColorRow (name, color) {
		var tableRow = $('<tr>').appendTo(this.table);
		$('<td>').html(name).appendTo(tableRow);

		var valueColumn = document.createElement('td');
		valueColumn = $('<td>').appendTo(tableRow);

		var colorDiv = $('<div>').addClass('colorbutton').appendTo(valueColumn);
		colorDiv.attr('title', '(' + color[0] + ', ' + color[1] + ', ' + color[2] + ')');
		var hexColor = JSM.RGBComponentsToHexColor(color[0] * 255.0, color[1] * 255.0, color[2] * 255.0);
		var colorString = hexColor.toString(16);
		while (colorString.length < 6) {
			colorString = '0' + colorString;
		}
		colorDiv.css('background', '#' + 'fff');
	};
}
class ImporterMenuItem {
	constructor(parentDiv, name, parameters) {
		this.parentDiv = parentDiv;
		this.parameters = parameters;

		this.menuItemDiv = null;
		this.isOpen = null;
		this.openCloseImage = null;
		this.contentDiv = null;

		this.Initialize(name);
	};

	Initialize (name) {
		this.menuItemDiv = $('<div>').addClass('menuitem').appendTo(this.parentDiv);
		if (IsSet(this.parameters)) {
			if (IsSet(this.parameters.id)) {
				this.menuItemDiv.attr('id', this.parameters.id);
			}
			if (IsSet(this.parameters.openCloseButton)) {
				this.AddOpenCloseButton();
			}
			if (IsSet(this.parameters.userButtons)) {
				var i, userButton;
				for (i = 0; i < this.parameters.userButtons.length; i++) {
					userButton = this.parameters.userButtons[i];
					this.AddUserButton(userButton);
				}
			}
		}

		var menuItemTextDiv = $('<div>').addClass('menuitem').html(name).attr('title', name).appendTo(this.menuItemDiv);
		if (IsSet(this.parameters) && IsSet(this.parameters.openCloseButton)) {
			menuItemTextDiv.css('cursor', 'pointer');
		}
	};

	AddSubItem (name, parameters) {
		return new ImporterMenuItem(this.contentDiv, name, parameters);
	};

	GetContentDiv () {
		return this.contentDiv;
	};

	AddOpenCloseButton () {
		var myThis = this;
		this.isOpen = false;
		this.contentDiv = $('<div>').addClass('menuitemcontent').hide().appendTo(this.parentDiv);
		this.openCloseImage = $('<img>').addClass('menubutton').attr('title', this.parameters.openCloseButton.title).appendTo(this.menuItemDiv);
		this.openCloseImage.attr('src', require('../images/closed.png'));
		this.menuItemDiv.click(function () {
			myThis.SetOpen(!myThis.isOpen);
		});
	};

	AddUserButton (userButton) {
		var userImage = $('<img>').addClass('menubutton').attr('title', userButton.title).appendTo(this.menuItemDiv);
		if (IsSet(userButton.id)) {
			userImage.attr('id', userButton.id);
		}
		if (IsSet(userButton.onCreate)) {
			userButton.onCreate(userImage, userButton.userData);
		}
		if (IsSet(userButton.onClick) || IsSet(userButton.onCtrlClick)) {
			userImage.click(function (event) {
				event.stopPropagation();
				if (event.ctrlKey && IsSet(userButton.onCtrlClick)) {
					userButton.onCtrlClick(userImage, userButton.userData);
				} else if (IsSet(userButton.onClick)) {
					userButton.onClick(userImage, userButton.userData);
				}
			});
		}
	};

	SetOpen (isOpen) {
		this.isOpen = isOpen;
		if (this.isOpen) {
			if (IsSet(this.parameters.openCloseButton.onOpen)) {
				this.parameters.openCloseButton.onOpen(this.contentDiv, this.parameters.openCloseButton.userData);
			}
			this.contentDiv.show();
			this.openCloseImage.attr('src', require('../images/opened.png'));
		} else {
			if (IsSet(this.parameters.openCloseButton.onClose)) {
				this.parameters.openCloseButton.onClose(this.contentDiv, this.parameters.openCloseButton.userData);
			}
			this.contentDiv.hide();
			this.openCloseImage.attr('src', require('../images/closed.png'));
		}
	};

	Highlight (highlight) {
		if (highlight) {
			this.menuItemDiv.addClass('highlighted');
		} else {
			this.menuItemDiv.removeClass('highlighted');
		}
	};

	IsHighlighted () {
		return this.menuItemDiv.hasClass('highlighted');
	};

}
class ImporterMenu {
	constructor(parentDiv) {
		this.parentDiv = parentDiv;
		this.parentDiv.empty();
	};
	AddGroup (name, parameters) {
		return new ImporterMenuItem(this.parentDiv, name, parameters);
	};
}
class ImporterButtons {
	constructor(parent) {
		this.buttonsDiv = $('<div>').attr('id', 'buttons').appendTo(parent);
	};

	AddLogo (title) {
		var logoDiv = $('<div>').attr('id', 'logo').html(title).appendTo(this.buttonsDiv);
		logoDiv.click(function () {
			location.hash = '';
			location.reload();
		});
	};

	AddYiZaoButton (title) {
		var logoDiv = $('<div>').attr('class', 'btn btn-primary screen').html(title).appendTo(this.buttonsDiv);
		logoDiv.click(function () {
			$('body').css('overflow', 'hidden');
			$('.make3d').addClass('on');
			$('.topbutton.close').show();
			$('.btn.screen').hide();
			Resize();
		});
	};


	AddButton (image, title, onClick, classify = '') {
		var buttonImage = $('<img>').addClass('topbutton ' + classify).attr('src', image).attr('title', title).appendTo(this.buttonsDiv);
		buttonImage.click(function () {
			onClick();
		});
	};

	AddToggleButton (image, toggleImage, title, onClick) {
		var buttonImage = $('<img>').addClass('topbutton').attr('src', image).attr('title', title).appendTo(this.buttonsDiv);
		var isOn = true;
		buttonImage.click(function () {
			isOn = !isOn;
			if (isOn) {
				buttonImage.attr('src', image);
			} else {
				buttonImage.attr('src', toggleImage);
			}
			onClick();
		});
	};
}
class ExtensionButtons {
	constructor(parent) {
		this.buttonsDiv = $('<div>').attr('id', 'extbuttons').appendTo(parent);
	};
	GetButtonsDiv () {
		return this.buttonsDiv;
	};
}
class ImporterProgressBar {
	constructor(parent) {
		this.parent = parent;
		this.borderDiv = null;
		this.contentDiv = null;
		this.maxCount = null;
		this.maxWidth = null;
	};
	Init (maxCount) {
		this.borderDiv = $('<div>').addClass('progressbarborder').appendTo(this.parent);
		this.contentDiv = $('<div>').addClass('progressbarcontent').appendTo(this.borderDiv);

		this.maxCount = maxCount;
		this.maxWidth = this.borderDiv.width();
		this.Step(0);
	};

	Step (count) {
		var step = this.maxWidth / this.maxCount;
		var width = count * step;
		if (count == this.maxCount) {
			width = this.maxWidth;
		}
		this.contentDiv.width(width);
	};
}
class ImporterViewer {
	constructor() {
		this.viewer = null;
		this.jsonData = null;
	}

	Init (canvasName) {
		var viewerSettings = {
			cameraEyePosition: [8.0, -6.0, 4.0],
			cameraCenterPosition: [0.0, 0.0, 0.0],
			cameraUpVector: [0, 0, 1]
		};

		this.viewer = new JSM.ThreeViewer();
		var canvas = document.getElementById(canvasName);
		if (!this.viewer.Start(canvas, viewerSettings)) {
			return false;
		}
		this.viewer.navigation.SetNearDistanceLimit(0.1);
		this.viewer.navigation.SetFarDistanceLimit(100000.0);
		this.viewer.SetClearColor('transparent');
		this.viewer.Draw();

		return true;
	};

	GetJsonData () {
		return this.jsonData;
	};

	SetJsonData (jsonData) {
		this.jsonData = jsonData;
	};

	RemoveMeshes () {
		this.viewer.RemoveMeshes();
	};
	ShowAllMeshes (inEnvironment) {
		this.RemoveMeshes();
		var myThis = this;
		var currentMeshIndex = 0;
		viewer = new JSM.ThreeViewer();
		var canvas = document.createElement('canvas');
		canvas.setAttribute('id', 'modelcanvas');
		viewer.Start(canvas, {
			cameraEyePosition: [8.0, -6.0, 4.0],
			cameraCenterPosition: [0.0, 0.0, 0.0],
			cameraUpVector: [0, 0, 1]
		})
		viewer.navigation.SetNearDistanceLimit(0.1);
		viewer.navigation.SetFarDistanceLimit(100000.0);
		viewer.SetClearColor('transparent');
		var environment = {
			onStart: function (taskCount /*, meshes*/) {
				inEnvironment.onStart(taskCount);
				viewer.EnableDraw(false);
			},
			onProgress: function (currentTask, meshes) {
				while (currentMeshIndex < meshes.length) {
					viewer.AddMesh(meshes[currentMeshIndex]);
					currentMeshIndex = currentMeshIndex + 1;
				}
				inEnvironment.onProgress(currentTask);
			},
			onFinish: function (meshes) {
				$('.box-l #modelcanvas').remove();
				$('.box-l').append(canvas);
				viewer.AdjustClippingPlanes(50.0);
				viewer.FitInWindow();
				viewer.EnableDraw(true);
				viewer.Draw();
				addButton(viewer, setdView);
				inEnvironment.onFinish(meshes);
			}
		};

		JSM.ConvertJSONDataToThreeMeshes(this.jsonData, this.Draw.bind(this), environment);
	};

	GetMeshesUnderPosition (x, y) {
		var objects = this.viewer.GetObjectsUnderPosition(x, y);
		var meshes = [];
		var i;
		for (i = 0; i < objects.length; i++) {
			if (objects[i].object instanceof THREE.Mesh) {
				meshes.push(objects[i].object);
			}
		}
		return meshes;
	};

	GetMeshesByMaterial (materialIndex) {
		var meshIndices = [];
		this.viewer.scene.traverse(function (current) {
			if (current instanceof THREE.Mesh) {
				if (current.originalJsonMaterialIndex == materialIndex) {
					if (meshIndices.length === 0 || meshIndices[meshIndices.length - 1] != current.originalJsonMeshIndex) {
						meshIndices.push(current.originalJsonMeshIndex);
					}
				}
			}
		});
		return meshIndices;
	};

	HighlightMesh (index, highlight) {
		this.viewer.scene.traverse(function (current) {
			if (current instanceof THREE.Mesh) {
				if (current.originalJsonMeshIndex == index) {
					if (highlight) {
						current.material.emissive.setHex(0x555555);
					} else {
						current.material.emissive.setHex(0);
					}
				}
			}
		});
	};

	FitInWindow () {
		this.viewer.FitInWindow();
	};

	FitMeshInWindow (index) {
		var meshes = [];
		this.viewer.scene.traverse(function (current) {
			if (current instanceof THREE.Mesh) {
				if (current.originalJsonMeshIndex == index) {
					meshes.push(current);
				}
			}
		});
		this.viewer.FitMeshesInWindow(meshes);
	};

	FitMeshesInWindow (meshIndices) {
		var meshes = [];
		this.viewer.scene.traverse(function (current) {
			if (current instanceof THREE.Mesh) {
				if (meshIndices.indexOf(current.originalJsonMeshIndex) != -1) {
					meshes.push(current);
				}
			}
		});
		this.viewer.FitMeshesInWindow(meshes);
	};

	AdjustClippingPlanes () {
		if (this.viewer.MeshCount() > 0) {
			this.viewer.AdjustClippingPlanes(50.0);
		}
	};

	SetFixUp () {
		this.viewer.navigation.EnableFixUp(!this.viewer.navigation.cameraFixUp);
	};

	SetNamedView (viewName) {
		var eye, center, up;
		if (viewName == 'z') {
			eye = new JSM.Coord(1.0, 0.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(0.0, 0.0, 1.0);
		} else if (viewName == '-z') {
			eye = new JSM.Coord(-1.0, 0.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(0.0, 0.0, -1.0);
		} else if (viewName == 'y') {
			eye = new JSM.Coord(1.0, 0.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(0.0, 1.0, 0.0);
		} else if (viewName == '-y') {
			eye = new JSM.Coord(-1.0, 0.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(0.0, -1.0, 0.0);
		} else if (viewName == 'x') {
			eye = new JSM.Coord(0.0, 1.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(1.0, 0.0, 0.0);
		} else if (viewName == '-x') {
			eye = new JSM.Coord(0.0, -1.0, 0.0);
			center = new JSM.Coord(0.0, 0.0, 0.0);
			up = new JSM.Coord(-1.0, 0.0, 0.0);
		} else {
			return;
		}

		this.viewer.cameraMove.Set(eye, center, up);
		this.viewer.FitInWindow();
	};

	Draw () {
		this.viewer.Draw();
	};
}
//ExampleExtension
class ExampleExtension {
	constructor() {
		this.ext = null;
	}
	IsEnabled () {
		return true;
	}
	Init (extensionInterface) {
		let _this = this;
		this.ext = extensionInterface;
		var buttonsDiv = this.ext.GetButtonsDiv()
		var buttonImage = $('<div>').html('分享预览').addClass('r-btn').attr('src', require('../images/example.png')).attr('title', '分享预览').appendTo(buttonsDiv);
		var myThis = this;
		buttonImage.click(function () {
			if (hasDev) {
				hasUpLoad();
			} else {
				service.get("/api/v1/loginUserCheck").then((res) => {
					if (res.code == 0) {
						Message({
							message: '请先登录！',
							type: 'error',
							duration: 1500
						})
					} else {
						hasUpLoad();
					}
				});
			}
		});
		buttonImage = $('<div>').html(`
			<label><input type="checkbox" />保密协议</label>
		`).addClass('r-btn').attr('src', require('../images/example.png')).attr('title', '分享预览').appendTo(buttonsDiv);
		var myThis = this;
		buttonImage.click(function () {

		});
		// buttonImage = $('<img>').addClass('topbutton').attr('src', require('../images/example.png')).attr('title', '惠享工业企业服务平台.').appendTo(buttonsDiv);
		// var myThis = this;
		// buttonImage.click(function () {
		// 	alert(JSON.stringify(myThis.ext.GetModelJson()));
		// });
	};
}
class ExtensionInterface {
	constructor(app) {
		this.app = app;
	}
	GetButtonsDiv () {
		return this.app.extensionButtons.GetButtonsDiv();
	};
	GetModelJson () {
		return this.app.viewer.GetJsonData();
	};
}

class ImporterApp {
	constructor(funs = {}) {
		this.canvas = null;
		this.viewer = null;
		this.fileNames = null;
		this.inGenerate = false;
		this.meshesGroup = null;
		this.materialMenuItems = null;
		this.meshMenuItems = null;
		this.extensions = [];
		this.importerButtons = null;
		this.extensionButtons = null;
		this.introControl = null;
		this.floatingDialog = null;
		this.isMobile = null;
		this.readyForTest = null;
		this.fileUploadId = "";
		funs.finish ? this.finish = funs.finish : null;
		funs.change ? this.change = funs.change : null;
		funs.parsing ? this.parsing = funs.parsing : null;
	}
	close () {
		this.viewer = new ImporterViewer();
		this.viewer.Init('modelcanvas');
	}
	Init () {
		if (!JSM.IsWebGLEnabled() || !JSM.IsFileApiEnabled()) {
			while (document.body.lastChild) {
				document.body.removeChild(document.body.lastChild);
			}

			var div = $('<div>').addClass('nosupport').appendTo($('body'));
			div.html([
				'<div id="nosupport">',
				this.GetWelcomeText(),
				'<div class="nosupporterror">You need a browser which supports the following technologies: WebGL, WebGLRenderingContext, File, FileReader, FileList, Blob, URL.</div>',
				'</div>'
			].join(''));
			return;
		}

		var myThis = this;
		$('.box-l').html(`<div class="loading"><span>文件加载中...</span></div><canvas id="modelcanvas" ></canvas>
		<div id="top"></div>`);
		this.extensionButtons = addButton();
		this.introControl = new FloatingControl();
		this.floatingDialog = new FloatingDialog();

		var match = window.matchMedia('(max-device-width : 600px)');
		this.isMobile = match.matches;
		window.addEventListener('resize', this.Resize.bind(this), false);
		this.Resize();
		var canvasName = 'modelcanvas';
		this.canvas = $('#' + canvasName);
		this.RegisterCanvasClick();

		window.addEventListener('dragover', this.DragOver.bind(this), false);
		window.addEventListener('drop', this.Drop.bind(this), false);
		let _this = this;
		$('#file').on('change', function () {
			_this.ProcessFiles(this.files, false);
		});

		window.onhashchange = this.LoadFilesFromHash.bind(this);
		var hasHashModel = this.LoadFilesFromHash();
		if (!hasHashModel && !this.isMobile) {
			this.ShowIntroControl();
		}
		$('body').on('click', '.box3', function () {
			_this.OpenFile();
		})
		this.viewer = new ImporterViewer();
		this.viewer.Init(canvasName);
	};
	ClearReadyForTest () {
		if (this.readyForTest !== null) {
			this.readyForTest.remove();
			this.readyForTest = null;
		}
	};
	SetReadyForTest () {
		this.readyForTest = $('<div>').attr('id', 'readyfortest').hide().appendTo($('body'));
	};
	AddExtension (extension) {
		if (!extension.IsEnabled()) {
			return;
		}

		var extInterface = new ExtensionInterface(this);
		extension.Init(extInterface);
	};
	ShowIntroControl () {
		var dialogText = [
			'<div class="importerdialog">',
			this.GetWelcomeText(),
			'</div>',
		].join('');
		this.introControl.Open({
			parent: this.canvas,
			text: dialogText
		});
		this.Resize();
	};
	HideIntroControl () {
		this.introControl.Close();
		this.Resize();
	};
	GetWelcomeText () {
		let _this = this;
		var welcomeText = `
		<div class="welcometitle">惠享在线图纸预览报价工具!</div>
		<div class="welcometextformats">支持stp/stl/igs/obj.</div>
		<div class="welcometext">Powered by <a target="_blank" href="https://www.huixianggongye.com/">惠享科技（长春）有限责任公司</a> .</div>
		<div class="box3"><span ><img src="${require('../images/u529.svg')}" alt="" class="img"> <span style="font-size: 10px; display: block;">点击或将文件拖拽到这里上传</span></span> </div>
		`;
		return welcomeText;
	};
	Resize () {
		function SetWidth (elem, value) {
			elem.width = value;
			elem.style.width = value + 'px';
		}

		function SetHeight (elem, value) {
			elem.height = value;
			elem.style.height = value + 'px';
		}
		var canvas = document.getElementById('modelcanvas');
		if (!canvas) return;
		SetHeight(canvas, $(".box-l").height() - 40);
		SetWidth(canvas, $(".box-l").width());
		Resize();
	};
	JsonLoaded (progressBar) {
		this.Generate(progressBar);
	};
	GenerateMenu () {
		function AddDefaultGroup (menu, name, id) {
			var group = menu.AddGroup(name, {
				id: id,
				openCloseButton: {
					title: '显示/隐藏 ' + name
				}
			});
			return group;
		}

		function AddInformation (infoGroup, jsonData) {
			var infoTable = new InfoTable(infoGroup.GetContentDiv());
			var materialCount = jsonData.materials.length;
			var vertexCount = 0;
			var triangleCount = 0;
			var mesh = null,
				triangles = null;
			for (var i = 0; i < jsonData.meshes.length; i++) {
				mesh = jsonData.meshes[i];
				vertexCount += mesh.vertices.length / 3;
				for (var j = 0; j < mesh.triangles.length; j++) {
					triangles = mesh.triangles[j];
					triangleCount += triangles.parameters.length / 9;
				}
			}
			infoTable.AddRow('材料数量', materialCount);
			infoTable.AddRow('顶点数', vertexCount);
			infoTable.AddRow('三角数', triangleCount);
		}

		function AddMaterial (importerApp, importerMenu, materialsGroup, materialIndex, material) {
			var materialMenuItem = materialsGroup.AddSubItem(material.name, {
				openCloseButton: {
					title: '显示/隐藏 信息',
					onOpen: function (contentDiv, material) {
						contentDiv.empty();
						var materialButtons = $('<div>').addClass('submenubuttons').appendTo(contentDiv);
						var highlightButton = $('<img>').addClass('submenubutton').attr('src', require('../images/highlightmesh.png')).attr('title', '按材质突出显示网格').appendTo(materialButtons);
						highlightButton.click(function () {
							importerApp.HighlightMeshesByMaterial(materialIndex);
						});
						var fitInWindowButton = $('<img>').addClass('submenubutton').attr('src', require('../images/fitinwindowsmall.png')).attr('title', '按材质在窗口中拟合网格').appendTo(materialButtons);
						fitInWindowButton.click(function () {
							importerApp.FitMeshesByMaterialInWindow(materialIndex);
						});
						var table = new InfoTable(contentDiv);

						table.AddColorRow('环境光', material.ambient);
						table.AddColorRow('扩散', material.diffuse);
						table.AddColorRow('高光', material.specular);
						table.AddRow('光泽度', material.shininess.toFixed(2));
						table.AddRow('不透明度', material.opacity.toFixed(2));
					},
					userData: material
				}
			});
			return materialMenuItem;
		}

		function AddMesh (importerApp, importerMenu, meshesGroup, mesh, meshIndex) {
			function AddMeshButtons (importerApp, contentDiv, meshName, meshIndex) {
				function CopyToClipboard (text) {
					var input = document.createElement('input');
					input.style.position = 'absolute';
					input.style.left = '0';
					input.style.top = '0';
					input.setAttribute('value', text);
					document.body.appendChild(input);
					input.select();
					document.execCommand('copy');
					document.body.removeChild(input);
				}

				var meshButtons = $('<div>').addClass('submenubuttons').appendTo(contentDiv);
				var fitInWindowButton = $('<img>').addClass('submenubutton').attr('src', require('../images/fitinwindowsmall.png')).attr('title', '使网格适合窗口').appendTo(meshButtons);
				fitInWindowButton.click(function () {
					importerApp.FitMeshInWindow(meshIndex);
				});
				var highlightButton = $('<img>').addClass('submenubutton').attr('src', require('../images/highlightmesh.png')).attr('title', '突出显示网格').appendTo(meshButtons);
				highlightButton.click(function () {
					importerApp.HighlightMesh(meshIndex);
				});
				var copyNameToClipboardButton = $('<img>').addClass('submenubutton').attr('src', require('../images/copytoclipboard.png')).attr('title', '将网格名称复制到剪贴板').appendTo(meshButtons);
				copyNameToClipboardButton.click(function () {
					CopyToClipboard(meshName);
				});
			}

			var visibleImage = null;
			var meshMenuItem = meshesGroup.AddSubItem(mesh.name, {
				id: 'meshmenuitem-' + meshIndex.toString(),
				openCloseButton: {
					title: '显示/隐藏 详情',
					onOpen: function (contentDiv, mesh) {
						contentDiv.empty();

						AddMeshButtons(importerApp, contentDiv, mesh.name, meshIndex);
						var table = new InfoTable(contentDiv);

						var min = new JSM.Coord(JSM.Inf, JSM.Inf, JSM.Inf);
						var max = new JSM.Coord(-JSM.Inf, -JSM.Inf, -JSM.Inf);
						var vertex = null;
						for (var i = 0; i < mesh.vertices.length; i = i + 3) {
							vertex = new JSM.Coord(mesh.vertices[i], mesh.vertices[i + 1], mesh.vertices[i + 2]);
							min.x = JSM.Minimum(min.x, vertex.x);
							min.y = JSM.Minimum(min.y, vertex.y);
							min.z = JSM.Minimum(min.z, vertex.z);
							max.x = JSM.Maximum(max.x, vertex.x);
							max.y = JSM.Maximum(max.y, vertex.y);
							max.z = JSM.Maximum(max.z, vertex.z);
						}
						var _x = (max.x - min.x).toFixed(2);
						var _y = (max.y - min.y).toFixed(2);
						var _z = (max.z - min.z).toFixed(2);
						table.AddRow('X 尺寸', _x);
						table.AddRow('Y 尺寸', _y);
						table.AddRow('Z 尺寸', _z);

						var triangleCount = 0;
						var triangles;
						for (i = 0; i < mesh.triangles.length; i++) {
							triangles = mesh.triangles[i];
							triangleCount += triangles.parameters.length / 9;
						}

						table.AddRow('顶点数', mesh.vertices.length / 3);
						table.AddRow('三角数', triangleCount);
						table.AddRow('体积', (_x * _y * _z).toFixed(2));
						table.AddRow('表面积', ((_x * _y) + (_y * _z) + (_x * _z)).toFixed(2) * 2);
					},
					userData: mesh
				},
				userButtons: [{
					id: 'showhidemesh-' + meshIndex,
					title: '显示/隐藏 网格',
					onCreate: function (image) {
						image.attr('src', require('../images/visible.png'));
						visibleImage = image;
					},
					onClick: function (image, meshIndex) {
						importerApp.ShowHideMesh(meshIndex);
					},
					onCtrlClick: function (image, meshIndex) {
						importerApp.IsolateMesh(meshIndex);
					},
					userData: meshIndex
				}]
			});

			meshMenuItem.isVisible = true;
			meshMenuItem.visibleImage = visibleImage;
			return meshMenuItem;
		}

		var jsonData = this.viewer.GetJsonData();
		var menu = $('#menu');
		var importerMenu = new ImporterMenu(menu);

		var filesGroup = AddDefaultGroup(importerMenu, '文件', 'filesmenuitem');
		filesGroup.AddSubItem(this.fileNames.main);
		var i;
		for (i = 0; i < this.fileNames.requested.length; i++) {
			filesGroup.AddSubItem(this.fileNames.requested[i]);
		}

		if (this.fileNames.missing.length > 0) {
			var missingFilesGroup = AddDefaultGroup(importerMenu, '丢失文件', 'missingfilesmenuitem');
			for (i = 0; i < this.fileNames.missing.length; i++) {
				missingFilesGroup.AddSubItem(this.fileNames.missing[i]);
			}
		}

		var infoGroup = AddDefaultGroup(importerMenu, '信息', 'informationmenuitem');
		AddInformation(infoGroup, jsonData);

		this.materialMenuItems = [];
		var materialsGroup = AddDefaultGroup(importerMenu, '用料', 'materialsmenuitem');
		var material, materialMenuItem;
		for (i = 0; i < jsonData.materials.length; i++) {
			material = jsonData.materials[i];
			materialMenuItem = AddMaterial(this, importerMenu, materialsGroup, i, material);
			this.materialMenuItems.push(materialMenuItem);
		}

		this.meshesGroup = AddDefaultGroup(importerMenu, '网格', 'meshesmenuitem');
		this.meshMenuItems = [];
		var mesh, meshMenuItem;
		selectFiles = selectFiles.map(item => {
			if (item.id == fileItem.id && item.news) {
				item.news = false;
				item.meshes = jsonData.meshes.map(items => items.name);
				fileItem = item;
				if (item.meshes.length == 1 && item.meshes[0] == '默认') {
					item.meshes = [item.name];
					this.parsing(item);
				} else {
					this.parsing(item);
				}
			}
			return item;
		});

		for (i = 0; i < jsonData.meshes.length; i++) {
			mesh = jsonData.meshes[i];
			meshMenuItem = AddMesh(this, importerMenu, this.meshesGroup, mesh, i);
			this.meshMenuItems.push(meshMenuItem);
		}

		this.Resize();
	};
	GenerateError (errorMessage) {
		this.viewer.RemoveMeshes();
		var menu = $('#menu');
		menu.empty();

		this.floatingDialog.Open({
			title: 'Error',
			text: '<div class="importerdialog">' + errorMessage + '</div>',
			buttons: [{
				text: '确认',
				callback: function (dialog) {
					dialog.Close();
				}
			}]
		});
	};
	Generate (progressBar) {
		function ShowMeshes (importerApp, progressBar, merge) {
			importerApp.inGenerate = true;
			var environment = {
				onStart: function (taskCount) {
					progressBar.Init(taskCount);
				},
				onProgress: function (currentTask) {
					progressBar.Step(currentTask + 1);
				},
				onFinish: function () {
					importerApp.GenerateMenu();
					importerApp.inGenerate = false;
					importerApp.SetReadyForTest();
					importerApp.viewer.Draw();
				}
			};

			if (merge) {
				var jsonData = importerApp.viewer.GetJsonData();
				importerApp.viewer.SetJsonData(JSM.MergeJsonDataMeshes(jsonData));
			}
			importerApp.viewer.ShowAllMeshes(environment);
		}

		var jsonData = this.viewer.GetJsonData();
		if (jsonData.materials.length === 0 || jsonData.meshes.length === 0) {
			this.GenerateError('Failed to open file. Maybe something is wrong with your file.');
			this.SetReadyForTest();
			return;
		}

		var myThis = this;
		if (jsonData.meshes.length > 250) {
			this.floatingDialog.Open({
				title: 'Information',
				text: '<div class="importerdialog">The model contains a large number of meshes. It can cause performance problems. Would you like to merge meshes?</div>',
				buttons: [{
					text: 'yes',
					callback: function (dialog) {
						ShowMeshes(myThis, progressBar, true);
						dialog.Close();
					}
				},
				{
					text: 'no',
					callback: function (dialog) {
						ShowMeshes(myThis, progressBar, false);
						dialog.Close();
					}
				}
				]
			});
		} else {
			ShowMeshes(myThis, progressBar, false);
		}
	};
	FitInWindow () {
		$('.btn.screen').show();
		$('.topbutton.close').hide();
		$('.make3d').removeClass('on');
		$('body').css('overflow', 'auto');
		Resize();
	};
	FitMeshInWindow (meshIndex) {
		this.viewer.FitMeshInWindow(meshIndex);
	};
	FitMeshesByMaterialInWindow (materialIndex) {


		var meshIndices = this.viewer.GetMeshesByMaterial(materialIndex);
		if (meshIndices.length === 0) {
			return;
		}
		this.viewer.FitMeshesInWindow(meshIndices);
	};
	SetFixUp () {
		this.viewer.SetFixUp();
	};
	SetNamedView (viewName) {
		this.viewer.SetNamedView(viewName);
	};
	SetView (viewType) {
		this.viewer.SetView(viewType);
	};
	ShowHideMesh (meshIndex) {
		var meshMenuItem = this.meshMenuItems[meshIndex];
		this.ShowHideMeshInternal(meshIndex, !meshMenuItem.isVisible);
		viewer.Draw();
	};
	IsolateMesh (meshIndex) {
		var meshMenuItem = null;

		var onlyThisVisible = true;
		if (!this.meshMenuItems[meshIndex].isVisible) {
			onlyThisVisible = false;
		} else {
			for (var i = 0; i < this.meshMenuItems.length; i++) {
				meshMenuItem = this.meshMenuItems[i];
				if (meshMenuItem.isVisible && i !== meshIndex) {
					onlyThisVisible = false;
					break;
				}
			}
		}

		for (i = 0; i < this.meshMenuItems.length; i++) {
			if (onlyThisVisible) {
				this.ShowHideMeshInternal(i, true);
			} else {
				if (i == meshIndex) {
					this.ShowHideMeshInternal(i, true);
				} else {
					this.ShowHideMeshInternal(i, false);
				}
			}
		}

		viewer.Draw();
	};
	ShowHideMeshInternal (meshIndex, isVisible) {
		var meshMenuItem = this.meshMenuItems[meshIndex];
		meshMenuItem.isVisible = isVisible;
		meshMenuItem.visibleImage.attr('src', meshMenuItem.isVisible ? require('../images/visible.png') : require('../images/hidden.png'));
		ShowMesh(meshIndex, meshMenuItem.isVisible);
	};
	HighlightMeshInternal (meshIndex, highlight) {
		var meshMenuItem = this.meshMenuItems[meshIndex];
		meshMenuItem.Highlight(highlight);
		this.viewer.HighlightMesh(meshIndex, highlight);
	};
	goSubmit (files, i = 0) {
		fileItem = files[i];
		hasUpLoad(() => {
			if (fileItem.response) {
				if (files.length - 1 == i) {
					this.finish(selectFiles);
				} else {
					this.goSubmit(files, i + 1);
				}
			}
		}, true);
	}
	goUp () {
		this.goSubmit(selectFiles)
	}
	openView (id) {
		//隐藏其他
		this.ClearReadyForTest();
		this.HideIntroControl();
		this.floatingDialog.Close();
		selectFiles.forEach(item => {
			if (item.id == id) {
				fileItem = item;
				if (fileItem.isTurn) {
					var url = fileItem.fileUrl.split('#');
					this.goShowView([`${window.config.dapi}/${url[1]}`], true);
				} else {
					this.goShowView([fileItem.file], false);
				}
			}
		})
	}
	deleteAll () {
		selectFiles = [];
		fileItem = {};
		this.ShowIntroControl();
		this.viewer = new ImporterViewer();
		this.viewer.Init('modelcanvas');
		var menu = $('#menu');
		menu.empty();
		this.change([], {});
	}
	delete (id) {
		if (id == fileItem.id) {
			this.ShowIntroControl();
			this.viewer = new ImporterViewer();
			this.viewer.Init('modelcanvas');
			var menu = $('#menu');
			menu.empty();
		}
		selectFiles = selectFiles.filter(item => {
			return item.id != id;
		});
		this.change(selectFiles, fileItem);
	}
	hides () {
		this.ClearReadyForTest();
		this.HideIntroControl();
		this.floatingDialog.Close();
	}
	//校验文件格式
	ProcessFiles (fileList, isUrl) {
		var _this = this;
		if (fileList.length) {
			let checkFormat = 0;
			let formatArray = fileFormat.split("/");
			fileList.forEach(item => {
				var nameArray = item.name.split(".");
				var type = nameArray[nameArray.length - 1];
				let hasType = formatArray.find((items) => items.toLowerCase() == type);
				if (!hasType) {
					checkFormat++;
				} else {
					selectFiles.push({
						file: item,
						id: new Date().getTime() + item.name,
						type,
						size: item.size,
						isTurn: type.toLowerCase() == 'stp' || type.toLowerCase() == "step",
						fileUploadId: "",
						fileEntityId: "",
						fileUrl: "",
						response: null,
						news: true,
						name: item.name,
						meshes: []
					});
				}
			});
			if (!checkFormat) {
				//隐藏其他
				this.ClearReadyForTest();
				this.HideIntroControl();
				this.floatingDialog.Close();
				//目前 一次只能预览一个文件
				fileItem = selectFiles[selectFiles.length - 1];
				this.change(selectFiles, fileItem);
				if (fileItem.isTurn) {
					if (hasDev) {
						hasUpLoad(() => {
							var url = fileItem.fileUrl.split('#');
							this.goShowView([`${window.config.dapi}/${url[1]}`], true);
						});
					} else {
						service.get("/api/v1/loginUserCheck").then((res) => {
							if (res.code == 0) {
								Message({
									message: '请先登录！',
									type: 'error',
									duration: 1500
								})
							} else {
								hasUpLoad(function () {
									var url = fileItem.fileUrl.split('#');
									_this.goShowView([`${window.config.dapi}/${url[1]}`], true);
								});
							}
						});
					}
				} else {
					this.goShowView([fileItem.file], isUrl);
				}
			} else {
				Message({
					message: `${checkFormat}个文件加载失败，请上传${fileFormat}格式文件！`,
					type: 'error',
					duration: 1500
				})
			}
		}
	};
	goShowView (userFiles, isUrl) {
		if (this.inGenerate) {
			return;
		}
		this.fileNames = null;
		var myThis = this;
		var processorFunc = JSM.ConvertFileListToJsonData;
		if (isUrl) {
			processorFunc = JSM.ConvertURLListToJsonData;
		}

		var menu = $('#menu');
		menu.empty();
		if (isUrl) {
			menu.html('<span class="loading">文件下载中...</span>');
		} else {
			menu.html('<span class="loading">加载文件...</span>');
		}
		$('.box-l .loading').show();
		processorFunc(userFiles, {
			onError: function () {
				Message({
					message: "文件加载失败！",
					type: "error"
				})
				return;
			},
			onReady: function (fileNames, jsonData) {
				myThis.fileNames = fileNames;
				myThis.viewer.SetJsonData(jsonData);
				menu.empty();
				var progressBar = new ImporterProgressBar(menu);
				myThis.JsonLoaded(progressBar);
				$('.box-l .loading').hide();
			}
		});
	}
	RegisterCanvasClick () {
		var myThis = this;
		var mousePosition = null;
		this.canvas.mousedown(function () {
			mousePosition = [event.pageX, event.pageY];
		});
		this.canvas.mouseup(function (event) {
			var mouseMoved = (mousePosition == null || event.pageX != mousePosition[0] || event.pageY != mousePosition[1]);
			if (!mouseMoved) {
				var x = event.pageX - $(this).offset().left;
				var y = event.pageY - $(this).offset().top;
				myThis.OnCanvasClick(x, y);
			}
			mousePosition = null;
		});
	};

	ScrollMeshIntoView (meshIndex) {
		if (meshIndex == -1) {
			return;
		}
		var menuItem = this.meshMenuItems[meshIndex];
		// menuItem.menuItemDiv.get(0).scrollIntoView();
	};

	HighlightMesh (meshIndex) {
		var menuItem = null;
		if (meshIndex != -1) {
			for (var i = 0; i < this.meshMenuItems.length; i++) {
				menuItem = this.meshMenuItems[i];
				if (i == meshIndex) {
					if (!menuItem.IsHighlighted()) {
						this.HighlightMeshInternal(i, true);
					} else {
						this.HighlightMeshInternal(i, false);
					}
				}
			}
		} else {
			for (i = 0; i < this.meshMenuItems.length; i++) {
				menuItem = this.meshMenuItems[i];
				if (menuItem.IsHighlighted()) {
					this.HighlightMeshInternal(i, false);
				}
			}
		}

		this.viewer.Draw();
	};

	HighlightMeshesByMaterial (materialIndex) {
		var meshIndices = this.viewer.GetMeshesByMaterial(materialIndex);
		if (meshIndices.length === 0) {
			return;
		}

		var meshIndex = null;
		this.HighlightMesh(-1);
		for (var i = 0; i < meshIndices.length; i++) {
			meshIndex = meshIndices[i];
			// meshMenuItem = this.meshMenuItems[meshIndex];
			this.HighlightMeshInternal(meshIndex, true);
		}

		this.meshesGroup.SetOpen(true);
		this.ScrollMeshIntoView(meshIndices[0]);
		this.viewer.Draw();
	};

	OnCanvasClick (x, y) {
		if (this.meshMenuItems == null) {
			return;
		}
		var objects = this.viewer.GetMeshesUnderPosition(x, y);
		var meshIndex = -1;
		if (objects.length > 0) {
			meshIndex = objects[0].originalJsonMeshIndex;
			this.meshesGroup.SetOpen(true);
		}

		this.HighlightMesh(meshIndex);
		this.ScrollMeshIntoView(meshIndex);
	};

	DragOver (event) {
		event.stopPropagation();
		event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';
	};

	Drop (event) {
		event.stopPropagation();
		event.preventDefault();
		this.ResetHash();
		debugger;
		this.ProcessFiles(event.dataTransfer.files, false);
	};
	OpenFile () {
		var fileInput = document.getElementById('file');
		fileInput.click();
	};

	ResetHash () {
		if (window.location.hash.length > 1) {
			window.location.hash = '';
		}
	};

	LoadFilesFromHash () {
		if (window.location.hash.length < 2) {
			return false;
		}
		var fileInput = $('#file');
		var hash = window.location.hash;
		if (hash == '#testmode') {
			fileInput.css('display', '');
			fileInput.css('position', 'absolute');
			fileInput.css('right', '10px');
			fileInput.css('bottom', '10px');
			return false;
		}

		fileInput.css('display', 'none');
		hash = hash.substr(1, hash.length - 1);
		var fileList = hash.split(',');
		this.ProcessFiles(fileList, true);
		return true;
	};
}
export {
	ImporterApp,
	ExampleExtension,
	Resize
}