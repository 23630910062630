<template>
  <div class="service-add">
    <h3>快速发布，精准对接</h3>
    <span class="tip">仅需4步，即可完成产品生产</span>
    <div class="add-flow">
      <div class="item" v-for="(item, index) in addFlowList" :key="item.id">
        <div>
          <i :class="['iconfont', item.icon]"></i>
          <p>{{ item.title }}</p>
        </div>
        <span v-if="index < addFlowList.length - 1">-----></span>
      </div>
    </div>
    <div class="add-main">
      <div class="form">
        <el-form
          :model="upForm"
          ref="upForm"
          :label-width="isWap ? '90vw' : '120px'"
          :label-position="isWap ? 'top' : 'left'"
          size="small"
          class="up-form"
          label-suffix="："
        >
          <el-form-item
            prop="title"
            label="企业名称"
            :rules="[
              {  trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="upForm.title"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>

          <el-form-item
            prop="userName"
            label="姓名"
            :rules="[
              { required: true, message: '请输入姓名', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="upForm.userName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>


          <el-form-item
            prop="mobile"
            label="手机号"
            :rules="[
              {
                required: true,
                validator: validatorPhone,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="upForm.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
   
          
          
          <el-form-item
            prop="email"
            label="需求名称"
            :rules="[
              {
                required: true, message: '请输入需求名称', 
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="upForm.email"
              placeholder="请输入需求名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="description"
            label="描述"
            :rules="[
              { required: true, message: '请输入描述', trigger: 'blur' },
            ]"
          >
            <el-input
              type="textarea"
              v-model="upForm.description"
              placeholder="请输入描述"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="validCode"
            label="图片验证码"
            v-show="!hadLogin"
            :rules="[
              {
                required: !hadLogin,
                message: '请输入图片验证码',
                trigger: 'blur',
              },
            ]"
          >
            <el-row>
              <el-col :span="isWap ? 12 : 18">
                <el-input
                  v-model="upForm.validCode"
                  placeholder="请输入图片验证码"
                ></el-input>
              </el-col>
              <el-col
                :span="isWap ? 11 : 5"
                :offset="1"
                style="text-align: right"
              >
                <img :src="this.validCode" @click="getValidCode" alt="" />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            prop="regValidCode"
            label="验证码"
            v-show="!hadLogin"
            :rules="[
              { required: !hadLogin, message: '请输入验证码', trigger: 'blur' },
            ]"
          >
            <el-row>
              <el-col :span="isWap ? 12 : 18">
                <el-input
                  v-model="upForm.regValidCode"
                  placeholder="请输入验证码"
                ></el-input>
              </el-col>
              <el-col
                :span="isWap ? 11 : 5"
                :offset="1"
                style="text-align: right"
              >
                <el-button
                  type="primary"
                  :loading="codeLoad"
                  :disabled="
                    codeText != '发送验证码' && codeText != '重新发送验证码'
                  "
                  @click="getCode"
                  >{{ codeText }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item class="box">
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
            <span class="box-deal" @click="showDeal = true"
              >《用户使用协议》</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="desc">
        <h3>坐享更<span>高效专业</span>的服务</h3>
        <div class="list">
          <div class="item">
            <p><img src="@/assets/images/public/icon_hj.jpg" alt="" /></p>
            <div>
              <p>成为供应商</p>
              <span>顾问全程服务</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_bz.jpg" alt="" /></p>
            <div>
              <p>发布资源/服务</p>
              <span>直到满意为止</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_b.jpg" alt="" /></p>
            <div>
              <p>精准推荐</p>
              <span>智能匹配、精准对接</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_s.jpg" alt="" /></p>
            <div>
              <p>需求直达</p>
              <span>垂直服务，分类明晰</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button
        type="primary"
        :size="isWap ? 'mini' : 'medium'"
        @click="submit"
        :loading="btnLoading"
        >免费发布需求</el-button
      >
   
    </div>
    <el-dialog
      center
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="showDeal"
      title="用户协议"
      :width="isWap ? '90vw' : '1000px'"
    >
      <div class="deal-main">
        <div class="WordSection1" style="layout-grid:15.6pt">

<p class="MsoNormal" align="left" style="text-align:left;line-height:75.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">用户协议</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">使用协议</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333"><br>
&nbsp;&nbsp;</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">一、定义</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">平台，是指您当前所浏览的网站平台。用户，包含注册用户和非注册用户，以下亦称为</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">“</span></b><b><span style="font-family:宋体;color:#333333">您</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">”</span></b><b><span style="font-family:宋体;color:#333333">。注册用户是指通过我们平台完成全部注册程序后，使用我们平台服务或我们网站资料的用户。非注册用户是指未进行注册、直接登录我们平台或通过其他网站进入我们平台直接或间接地使用我们平台服务或我们网站站资料的用户。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">协议方，本协议中协议双方合称</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">“</span></b><b><span style="font-family:宋体;color:#333333">协议方</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">”</span></b><b><span style="font-family:宋体;color:#333333">。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">二、协议的效力</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">在您按照注册页面提示填写信息、阅读并同意本协议完成全部注册程序后，您即受本协议的约束。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">本协议内容包括本协议正文《隐私政策》，所有我们已经发布的或将来可能发布的规则为本协议不可分割的组成部分，与本协议具有同等法律效力。除另行明确声明外，任何我司网站提供的服务（以下称为</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">“</span></b><b><span style="font-family:宋体;color:#333333">我们平台服务</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">”</span></b><b><span style="font-family:宋体;color:#333333">）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用我们平台服务；如您继续访问和使用我们平台服务，即视为您已确知并完全同意本协议各项内容。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">我们有权根据国家法律法规的更新、产品和服务规则的调整需要不时地制订、修改本协议或各类规则，并提前以网站公示的方式进行公示。如您继续使用我们平台服务的，即表示您接受经修订的协议和规则。如发生有关争议时，以我们最新的相关协议和规则为准。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">三、注册</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">在您完成注册程序使用我们平台服务后，您应当是具备相应民事行为能力的自然人（十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人）、法人或其他组织。若您不具备前述主体资格，则您及您的家长或法定监护人（以下统称</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">"</span></b><b><span style="font-family:宋体;color:#333333">监护人</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">"</span></b><b><span style="font-family:宋体;color:#333333">）应承担因此而导致的一切后果，且我们有权注销您的账户，并向您及您的监护人索偿。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">我们非常重视对青少年个人信息搜集和使用的安全性的保护。我们建议，任何未满</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">18</span></b><b><span style="font-family:宋体;color:#333333">周岁的未成年人参加网上活动应事先取得其监护人可经查证的同意并遵守《全国青少年网络文明公约》。监护人应承担未成年人网络活动风险及保护未成年人相关网络隐私的首要责任。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">在您签署本协议，完成注册程序后，您应对您的用户名、登录密码的安全，以及对通过您的账户和密码实施的行为负责，因此所衍生的任何损失或损害，我们无法也不承担任何责任。除非有法律规定或行政司法机关的指令，且征得我们的同意，否则您的用户名、登录密码不得以任何方式转让、借用、赠与、继承（与账户相关的财产权益除外）</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">&lt;</span></b><b><span style="font-family:宋体;color:#333333">或</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">&gt;</span></b><b><span style="font-family:宋体;color:#333333">在第三方平台上进行展示或售卖。否则，由此给您（或我们、任何第三方）造成的一切损失，概由您自行承担（或者负责赔偿）。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">4.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">您在注册帐号或使用我们平台服务的过程中，应提供合法、真实、准确的个人资料，您填写的个人资料有变动的，应及时进行更新。如果因您提供的个人资料不合法、不真实、不准确的，您需承担因此引起的相应责任及后果，并且我们保留终止您使用我们各项服务的权利。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">5.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">您了解并同意遵守本协议条款，在通过我们平台完成注册程序之后，即可成为我们平台注册用户。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">6.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">您不得通过任何手段恶意注册我们网站帐号，包括但不限于以牟利、炒作、套现等为目的多个账号注册，您亦不得盗用其他用户帐号。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span style="font-family:宋体;color:#333333">四、我们平台服务使用规范</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">通过我们平台，您可以按照我们的规则发布课程信息。但所发布之信息不得含有如下内容：</span></b><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;color:#333333">&nbsp;</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1) </span></b><b><span style="font-family:宋体;color:#333333">反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律、行政法规实施的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2)</span></b><b><span style="font-family:宋体;color:#333333">煽动危害国家安全、泄露国家秘密、颠覆国家政权，推翻社会主义制度的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3)</span></b><b><span style="font-family:宋体;color:#333333">煽动分裂国家、破坏国家统一、损害国家荣誉和民族利益的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">4)</span></b><b><span style="font-family:宋体;color:#333333">煽动民族仇恨、民族歧视，破坏民族团结的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">5)</span></b><b><span style="font-family:宋体;color:#333333">捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">6)</span></b><b><span style="font-family:宋体;color:#333333">进行政治宣传或破坏国家宗教政策、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">7)</span></b><b><span style="font-family:宋体;color:#333333">公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">8)</span></b><b><span style="font-family:宋体;color:#333333">损害国家机关信誉的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">9)</span></b><b><span style="font-family:宋体;color:#333333">其他违反宪法和法律法规的；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">在接受我们服务的过程中，您不得从事下列行为：</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1)</span></b><b><span style="font-family:宋体;color:#333333">在使用我们平台服务过程中实施的所有行为均遵守国家法律、法规等规范文件及我们平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保我们免于因此产生任何损失或增加费用。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2)</span></b><b><span style="font-family:宋体;color:#333333">不发布国家禁止发布的信息，不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3)</span></b><b><span style="font-family:宋体;color:#333333">不对我们平台上的任何数据作商业性利用，包括但不限于在未经我们事先书面同意的情况下，以复制、传播等任何方式使用我们平台上展示的资料。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">4)</span></b><b><span style="font-family:宋体;color:#333333">不使用任何装置、软件或例行程序干预或试图干预我们平台的正常运作或正在我们平台上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸我们平台网络设备的行动。</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3.&nbsp;</span></b><b><span style="font-family:宋体;color:#333333">您同意，在发现本网站任何内容不符合法律规定，或不符合本用户协议规定的，您有义务及时通知我们。如果您发现您的个人信息被盗用、您的版权或者其他权利被侵害，请将此情况告知我们并同时提供如下信息和材料：</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">1)</span></b><b><span style="font-family:宋体;color:#333333">侵犯您权利的信息的网址，编号或其他可以找到该信息的细节；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">2)</span></b><b><span style="font-family:宋体;color:#333333">您是所述的版权或者其他权利的合法拥有者的权利证明；</span></b></p>

<p class="MsoNormal" align="left" style="text-align:left;line-height:21.0pt;
vertical-align:baseline"><b><span lang="EN-US" style="font-family:&quot;Arial&quot;,sans-serif;
color:#333333">3)</span></b><b><span style="font-family:宋体;color:#333333">您的联系方式，包括联系人用户名，地址，电话号码和电子邮件；</span></b></p>

<p class="MsoNormal"><span lang="EN-US">&nbsp;</span></p>

</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agree">我已阅读并同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import upload from "./upload";
export default {
  components: {
    upload,
  },
  computed: {
    isWap() {
      return this.$store.state.isWap;
    },
    hadLogin() {
      return this.$store.state.userInfo.userCode;
    },
    upForm: {
      get() {
        {
          let { mobile, userName, email } = this.$store.state.userInfo;
          this.forms = {
            ...this.forms,
            mobile,
            userName,
            email,
          };
          return this.forms;
        }
      },
      set(val) {
        this.forms = val;
      },
    },
  },
  data() {
    return {
      forms: {
        mobile: "",
        regValidCode: "",
        categoryCode: [],
        userName: "",
        email: "",
        description: "",
        files: "",
        title: "",
        validCode: "",
      },
      btnLoading: false,
      validCode: "",
      codeText: "发送验证码",
      codeLoad: false,
      showDeal: false,
      checked: false,
      files: [],
      filesName: "",
      action: "",
      formData: {},
      typeList: [],
      addFlowList: [
        { id: 1, title: "发布需求", icon: "icon-tijiao" },
        { id: 2, title: "确认审核", icon: "icon-fenxi" },
        { id: 3, title: "精准匹配", icon: "icon-fuwushang" },
        { id: 4, title: "资源对接", icon: "icon-yueduhuizhi" },
      ],
      bizKey: "",
      bizType: "yzTaskMain_file",
      fileUploadId: "",
      idWorker: "",
      tipText: "",
      loadUps: {},
    };
  },
  mounted() {
    this.getValidCode();
    this.getClassifyData();
  },
  methods: {
    validatorEmail(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (
        value != "" &&
        !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        )
      ) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    },
    getValidCode() {
      this.validCode = "";
      this.$nextTick(() => {
        this.validCode = `${
          window.config.api
        }/validCode?${new Date().getTime()}`;
      });
    },
    getCode() {
      let i = 0;
      this.$refs.upForm.validateField(["mobile", "validCode"], (err) => {
        if (!err) {
          i++;
          if (i == 2) {
            this.codeLoad = true;
            this.$api
              .post(
                `/api/v1/getPostTaskMainValidCode?username=${this.upForm.mobile}&validCode=${this.upForm.validCode}`
              )
              .then((res) => {
                this.codeLoad = false;
                this.codeText = "60s后重新获取";
                this.noteDisabled = true;
                let _i = 59;
                let _set = setInterval(() => {
                  if (_i > 0) {
                    this.codeText = `${_i}s后重新获取`;
                    _i--;
                  } else {
                    this.codeText = "发送验证码";
                    this.noteDisabled = false;
                    clearInterval(_set);
                  }
                }, 1000);
              })
              .catch((err) => {
                this.codeLoad = false;
                this.getValidCode();
              });
          }
        }
      });
    },
    validatorPhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value != "" && !/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    relevance(files) {
      this.files = files;
      this.goRelevance(files);
    },
    goRelevance(files, i = 0) {
      let params = {
        bizKey: this.bizKey,
        bizType: this.bizType,
        fileUploadId: files[i].fileUploadId,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/unFileAndForm", this.$common.getFormData(params))
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.btnLoading = false;
              this.$message({
                message: this.tipText,
                type: "success",
                duration: 1500,
                onClose: () => {
                  //location.href = "/service/list";
                  this.loadUps.close();
                },
              });
              this.loadUps.close ? this.loadUps.close() : null;
            } else {
              i++;
              this.goRelevance(files, i);
            }
          } else {
            this.loadUps.close ? this.loadUps.close() : null;
          }
        })
        .catch((err) => {
          this.btnLoading = false;
          this.loadUps.close ? this.loadUps.close() : null;
        });
    },
    upService(fn) {
      let {
        mobile,
        regValidCode,
        categoryCode,
        userName,
        email,
        description,
        title,
        validCode,
      } = this.upForm;
      categoryCode = categoryCode[categoryCode.length - 1];
      this.$api
        .post(
          `/api/v1/postTaskMain`,
          this.$common.getFormData({
            mobile,
            validCode,
            regValidCode,
            categoryCode,
            userName,
            email,
            description,
            title,
            isAgree: this.checked ? "1" : "",
          }),
          { Headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then((res) => {
          if (fn && res.code == 1) {
            this.bizKey = res.data.id;
            this.tipText = res.message;
            this.$nextTick(() => {
              fn();
            });
          } else {
            this.btnLoading = false;
            if (res.code == 1) {
              this.$message({
                message: res.message,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.loadUps.close();
                 // location.href = "/service/list";
                },
              });
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          }
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    submit() {
      this.$refs.upForm.validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message({
              message: "请阅读并同意《用户使用协议》",
              type: "error",
            });
            return;
          }
          this.btnLoading = true;
          if (this.filesName != "") {
            this.upService(() => {
              this.$refs.upload.submit();
            });
          } else {
            this.upService();
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    upSuccess(files) {
      this.loadUps = this.$message({
        message: "正在处理文件，请不要关闭页面",
        iconClass: "el-icon-loading",
        type: "success",
        duration: 0,
      });
      this.relevance(files);
    },
    agree() {
      this.showDeal = false;
      this.checked = true;
    },
    inits() {
      this.checked = false;
      this.upForm = {
        phone: "",
        regValidCode: "",
        categoryCode: "",
        userName: "",
        email: "",
        description: "",
        files: "",
        title: "",
      };
    },
    getClassifyData() {
      this.$sapi.get("/api/v1/getYzTaskCategoryList.json").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        this.typeList = pList.map((item) => {
          if (_json[item.id] && _json[item.id].length) {
            item.children = _json[item.id].map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
            item.children = item.children.map((items) => {
              if (_json[items.id] && _json[items.id].length) {
                items.children = _json[items.id].map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });
              }
              return items;
            });
          }
          return {
            value: item.id,
            label: item.name,
            children: item.children,
          };
        });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/up.scss";
</style>