<template>
  <div class="make3d">
    <div class="file-list" v-show="boxRight">
      <h3>文件队列</h3>
      <div class="list">
        <div
          :class="['item', item.on ? 'on' : '']"
          v-for="item in fileList"
          :key="item.id"
          @click="open(item)"
        >
          <p>{{ item.name }}</p>
          <span @click.stop="del(item)" class="el-icon-delete"></span>
        </div>
      </div>
    </div>
    <div :class="['box-l',!boxRight?'on':'']">
      
    </div>
    <div class="box-r" v-show="boxRight">
      <div class="title">
        <h3>模型数据 <i class="iconfont icon-shuju"></i></h3>
        <p>
          如有您有三维或者二维数据文件，您可以在此窗口上传您的文件，我们
          将根据您的需求，快速提供免费的报价服务，以便您更好的选择服务商。
        </p>
      </div>
      <div id="menu"></div>
      <div class="b-btn">
        <span @click="$router.push({ path: '/supplier/list' })"
          >快速找工厂</span
        >
        <span @click="up">人工快速报价</span>
      </div>
    </div>
    <input type="file" v-if="isFileShow" id="file" style="display: none" />
    <div style="display: none">
      <a
        href="https://www.huixianggongye.com/"
        target="_blank"
        title="惠享工业企业服务平台"
      >
        惠享工业企业服务平台
      </a>
    </div>
  </div>
</template>
<script>
import { ImporterApp, ExampleExtension, Resize } from "./include";
export default {
  props: {
    boxRight: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      importerApp: {},
      fileList: [],
      isFileShow: true,
      modelcanvasShow: false,
    };
  },
  methods: {
    reset() {
      this.importerApp.deleteAll();
    },
    open(item) {
      this.importerApp.openView(item.id);
    },
    up() {
      document.querySelector(".topbutton.close").click();
      this.$emit("up");
    },
    del(item) {
      this.isFileShow = false;
      this.$confirm(`您确定要删除${item.name}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.isFileShow = true;
          this.$nextTick(() => {
            this.importerApp.delete(item.id);
          });
        })
        .catch((err) => {
          this.isFileShow = true;
        });
    },
    goResize() {
      Resize();
    },
    submit() {
      this.importerApp.goUp();
    },
    showView(url){
      this.importerApp.hides();
      this.importerApp.goShowView([url], true);
    }
  },
  mounted() {
    this.importerApp = new ImporterApp({
      finish: (files) => {
        this.$emit("success", files);
      },
      change: (files) => {
        this.fileList = files;
        this.modelcanvasShow = false;
      },
      parsing: (item) => {
        this.modelcanvasShow = true;
        this.$emit("parsing", item.meshes);
      },
    });
    this.importerApp.Init();
    // ExtensionIncludes
    this.importerApp.AddExtension(new ExampleExtension());
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>